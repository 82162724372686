<template>
  <div id="wager">
    <div class="wager-box" v-if="phase.includes('wager') || phase.includes('guess')">
      <h4 class="wager-text" >Highest Wager: {{highestWager.name}}</h4>
      <h4 class="wager-text">Wager: {{highestWager.wager}}</h4>
      <div class="selection">
        <paw-print
          class="left-arrow"
          v-on:click="decrementWager"
          :class="{'disabled': disableLeftButton || disableWagerSystem}"
        />
        <div class="non-selectable">
          <h4>
            {{Math.min(playerWager, playedCards)}}
          </h4>
        </div>
        <paw-print class="right-arrow"
          v-on:click="incrementWager"
          :class="{'disabled': disableRightButton || disableWagerSystem}"
        />
      </div>
      <div class="button-position">
        <button class="wager-button" :class="{'conditional-hover': (gameTurn === turn) && !disableWagerSystem}" v-on:click="makeWager" :disabled="gameTurn !== turn || disableWagerSystem">Wager</button>
        <button class="wager-button" :class="{'conditional-hover': (gameTurn === turn && highestWager.wager !== 0) && !disableWagerSystem}" v-on:click="pass" :disabled="gameTurn !== turn || highestWager.wager === 0 || disableWagerSystem">Pass</button>
      </div>
    </div>
    <div class="no-wagers-yet" v-else>
      No Wagers Yet
    </div>
  </div>
</template>

<script>
import PawPrint from '../assets/PawPrint.svg'

export default {
  props: {
    phase: String,
    currentWager: Object,
    wagerRange: Array,
    turn: Number,
    gameTurn: Number,
    room: String,
    users: Object,
    wager: Number,
    playedCards: Number
  },
  components: {
    PawPrint
  },
  computed: {
    disableWagerSystem() {
      return !this.phase.includes('wager')
    },
    disableLeftButton() {
      return this.gameTurn !== this.turn || this.playerWager <= this.currentWager.wager + 1
    },
    disableRightButton() {
      return this.gameTurn !== this.turn || this.playerWager >= this.playedCards
    },
    highestWager() {
      if(this.currentWager.wager !== 0){
        return {
          name: this.playerName(this.currentWager.id),
          wager: this.currentWager.wager
        }
      } else {
        return {
          name: "None",
          wager: 0
        }
      }
    }
  },
  watch: {
    currentWager() {
      this.playerWager = this.currentWager.wager + 1
    }
  },
  data() {
    return {
      playerWager: 0
    }
  },
  methods: {
    playerName(id) { //come back to this data structure
      return Object.values(this.users).find((user) => user.turn === id).username
    },
    makeWager() {
      this.$store.state.socket.emit('wager', {room: this.room, wager: Math.max(this.playerWager, this.currentWager.wager + 1)})
      this.playerWager = 0
    },
    pass() {
      this.$store.state.socket.emit('pass', {room: this.room})
    },
    decrementWager() {
      if(!this.disableLeftButton || this.disableWagerSystem){
        this.playerWager -= 1
      }
    },
    incrementWager() {
      if(!this.disableRightButton || this.disableWagerSystem){
        this.playerWager += 1
      }
    },
  }
}
</script>

<style scoped>
.wager-text {
  margin: 1rem;
}

.no-wagers-yet{
  border: .8rem solid grey;
  border-radius: 2rem;
  background: white;
  color: black;
  height: 9.5rem;
  width: 9.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  word-wrap: normal;
  text-align: center;
  justify-content: center;
  font-size: 120%;
  box-sizing: border-box;
  padding: 1rem;
}

.wager-box{
  border: .8rem solid grey;
  border-radius: 2rem;
  width: 15rem;
  margin-bottom: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

.selection {
  display: flex;
  justify-content: center;
  padding: .2rem;
}

.button-position {
  display: flex;
  justify-content: center;
}

.wager-button{
  border: .4rem solid grey;
  background: none;
  border-radius: 2rem;
  padding: .5rem;
  margin: .3rem;
}

.conditional-hover:hover{
  background: grey;
}

.left-arrow{
  width: 2rem;
  height: 2rem;
  transform: rotate(-90deg);
  padding: .4rem;
}

.right-arrow{
  width: 2rem;
  height: 2rem;
  transform: rotate(90deg);
  padding: .4rem;
}

.disabled {
  filter: opacity(50%)
}

.non-selectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  height: 1rem;
  bottom: 1.6rem;
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 160%;
}

</style>
