<template>
  <div v-if="outOfGame" class="banner">
    <h1>
      Game Over
    </h1>
  </div>
  <span v-else>
    <span v-for="(card, index) in cards" v-bind:key="index">
      <span :id=index v-on:click=playCard(index) >
        <component :is="cat" v-if="card === true" class="card" :class="{'test': disableCards}"/>
        <component :is="flower" v-else class="card" :class="{'test': disableCards}"/>
      </span>
    </span>
  </span>
</template>

<script>
import BlackFlower from '../assets/blackCards/BlackFlower.svg'
import BlackCat from '../assets/blackCards/BlackCat.svg'
import BlueFlower from '../assets/blueCards/BlueFlower.svg'
import BlueCat from '../assets/blueCards/BlueCat.svg'
import GreenFlower from '../assets/greenCards/GreenFlower.svg'
import GreenCat from '../assets/greenCards/GreenCat.svg'
import PinkFlower from '../assets/pinkCards/PinkFlower.svg'
import PinkCat from '../assets/pinkCards/PinkCat.svg'
import RedFlower from '../assets/redCards/RedFlower.svg'
import RedCat from '../assets/redCards/RedCat.svg'
import YellowFlower from '../assets/yellowCards/YellowFlower.svg'
import YellowCat from '../assets/yellowCards/YellowCat.svg'

export default {
  props: {
    cards: Array,
    gameTurn: Number,
    turn: Number,
    phase: String,
    room: String,
    registeredName: String,
    outOfGame: Boolean,
    color: Number
  },
  components: {
    BlackFlower,
    BlackCat,
    BlueFlower,
    BlueCat,
    GreenFlower,
    GreenCat,
    PinkFlower,
    PinkCat,
    RedFlower,
    RedCat,
    YellowFlower,
    YellowCat
  },
  computed: {
    disableCards() {
      return (this.gameTurn === this.turn) && (this.phase.toLowerCase().includes('play') || this.phase.toLowerCase().includes('self'))
    },
    flower() {
      let component = {}
      switch(this.color){
      case 0:
        component = BlackFlower
        break
      case 1:
        component = BlueFlower
        break
      case 2:
        component = GreenFlower
        break
      case 3:
        component = PinkFlower
        break
      case 4:
        component = RedFlower
        break
      case 5:
        component = YellowFlower
        break
      }
      return component
    },
    cat() {
      let component = {}
      switch(this.color){
      case 0:
        component = BlackCat
        break
      case 1:
        component = BlueCat
        break
      case 2:
        component = GreenCat
        break
      case 3:
        component = PinkCat
        break
      case 4:
        component = RedCat
        break
      case 5:
        component = YellowCat
        break
      }
      return component
    },
  },
  methods: {
    playCard(index) {
      if(this.disableCards) {
        if(this.phase.toLowerCase().includes('play')){
          this.$store.state.socket.emit('play card', {index: index, room: this.room})
        } else if (this.phase.toLowerCase().includes('self')){
          this.$store.state.socket.emit("self choice", {
            index: index,
            room: this.room,
          });
        }
      }
    }
  }
}
</script>

<style scoped>
.test:hover {
  opacity: .5;
}

.banner {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
}
</style>
