<template>
  <div v-if="phase.includes('skull')">
    <span v-for="(number, index) in numberOfCards" v-bind:key="index">
      <span :id="index" v-on:click="removeCard(index)">
        <component :is="back(removeCardColor)" class="card test"/>
      </span>
    </span>
  </div>
</template>

<script>
import BlackBack from "../assets/blackCards/BlackBack.svg"
import BlueBack from '../assets/blueCards/BlueBack.svg'
import GreenBack from '../assets/greenCards/GreenBack.svg'
import PinkBack from '../assets/pinkCards/PinkBack.svg'
import RedBack from '../assets/redCards/RedBack.svg'
import YellowBack from '../assets/yellowCards/YellowBack.svg'

export default {
  props: {
    phase: String,
    numberOfCards: Number,
    room: String,
    removeCardColor: Number
  },
  components: {
    BlackBack,
    BlueBack,
    GreenBack,
    PinkBack,
    RedBack,
    YellowBack
  },
  methods: {
    removeCard(index) {
      this.$store.state.socket.emit("self choice", {
        index: index,
        room: this.room,
      });
    },
    back(playerColor) {
      let component = {}
      switch(playerColor){
      case 0:
        component = BlackBack
        break
      case 1:
        component = BlueBack
        break
      case 2:
        component = GreenBack
        break
      case 3:
        component = PinkBack
        break
      case 4:
        component = RedBack
        break
      case 5:
        component = YellowBack
        break
      }
      return component
    }
  },
};
</script>

<style scoped>
.test:hover {
  opacity: .5;
}
</style>
