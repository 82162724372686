<template>
  <div class="disconnect-modal" :class="{'show-modal': disconnect}">
    <div class="modal-content">
      <h1>{{disconnectedUser}} disconnected</h1>
      <div class="route-home-position">
        <router-link to="/" class="route-home">Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disconnect: Boolean,
    disconnectedUser: String
  }
}
</script>

<style scoped>
.disconnect-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: .5rem 1.5rem;
  width: 24rem;
  border-radius: 0.5rem;
}
.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.route-home {
  padding: 10px;
  border: 2px solid #eee;
  text-decoration: none;
  border-radius: 150px;
  background: #b3b3b3ff;
  color: black;
  outline: none;
}
.route-home:hover {
  background-color: darkgray;
}
.route-home-position{
  text-align: center;
  margin: 10px;
}
</style>
