<template>
  <div class="url-container">
    Use this link to invite friends to play
    <span class="url"> {{url}} </span>
  </div>
</template>

<script>
export default {
  data(){
    return {
      url: window.location.href
    }
  }
}
</script>

<style scoped>
.url-container {
  text-align: center;
}

.url {
  text-decoration: underline;
}
</style>
