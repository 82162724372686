<template>
  <div class="flex-container">
    <div class="player-names-container">
      <h3 class="player-title">
        Players
      </h3>
      <div v-for="data in users" v-bind:key="data.turn" class="username">
        <span v-if="data.turn === turn">
          <input
            v-if="this.editField === true"
            class="username-input"
            v-model="username"
            :placeholder="data.username"
            v-on:keyup.enter='registerUsername(username)'
            ref="userInput"
          />
          <span v-else class="username-display">
            {{data.username}}
          </span>
          <paw-print class="edit-icon" v-on:click="toggleEditField()"/>
          <color-input
            :room="room"
            :users="users"/>
        </span>
        <span v-else class="username-display">
          {{data.username}}
        </span>
        <span v-if="data.turn === 0" class="host">
          Host
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import PawPrint from '../assets/PawPrint.svg'
import ColorInput from './ColorInput.vue'

export default {
  components: {
    PawPrint,
    ColorInput
  },
  props: {
    room: String,
    users: Object,
    turn: Number,
  },
  methods: {
    registerUsername(username) {
      this.$store.state.socket.emit('username', {room: this.room, username: username})
      this.editField = false
    },
    toggleEditField(){
      this.editField = !this.editField
      this.$nextTick(() => {
        if(this.$refs.userInput !== null){
          this.$refs.userInput.focus()
        }
      })
    },

  },
  data() {
    return {
      username: '',
      editField: false
    }
  }
}
</script>

<style scoped>

.flex-container {
  display: flex;
  justify-content: center;
}

.player-names-container {
  padding-bottom: .5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  border: 2px solid gray;
  border-radius: .4rem;
  width: 33%;
}

.player-title {
  text-align: center;
  margin-top: .5rem;
  margin-bottom: .5rem;
  /* padding: .6rem; */
}

.edit-icon {
  height: 1rem;
  width: 1rem;
  margin-right: .5rem;

}

.username-input {
  border-radius: 4px;
  padding: .3rem;
  box-sizing: border-box;
  font-size: 100%;
  font-family: inherit;
  margin-bottom: -.3rem;
  margin-top: -.3rem;
}

.username-input::-moz-placeholder {
  opacity: 1;
  color: grey;
}
.username-input::-webkit-input-placeholder {
  padding-left: 1rem;
  color: grey;
}

.username-display {
  border: 2px solid gray;
  border-radius: 4px;
  padding: 5px 5px 5px 5px;
  margin-right: .3rem;
}

.username {
  padding: .6rem;
  /* border: 2px solid red; */
}

.host {
  border: 2px solid  #eee;
  border-radius: 1rem;
  padding-right: .5rem;
  padding-top: .2rem;
  padding-bottom : .2rem;
  background:  #b3b3b3ff;
}
/* .username-input {
  width:200px;
  padding:20px;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #eee;
  border-radius: 150px;
  outline: none;
} */
</style>
