<template>
  <div id="game">
    <div class="turn" v-if="gameTurn >= 0">
      {{instruction}}
    </div>
    <game-board
      :gameTurn="gameTurn"
      :currentUser="turn"
      :playedCards="playedCards"
      :phase="phase"
      :correctGuesses="correctGuesses"
      :users="users"
      :skullPlayerIndex="skullPlayerIndex"
      :color="color"
    />
    <div class="player-box" v-if="gameTurn >= 0">
      <div class="card-username">
        <keep-alive>
          <component :is="userComponent" class="cat-icon"></component>
        </keep-alive>
        <h2 id="username">{{ registeredName }}</h2>
      </div>
      <div class="player-actions">
        <play-card
          :cards="cards"
          :gameTurn="gameTurn"
          :turn="turn"
          :phase="phase"
          :room="room"
          :registeredName="registeredName"
          :outOfGame="outOfGame"
          :color="color"
        />
        <wager
          :phase="phase"
          :currentWager="currentWager"
          :wagerRange="wagerRange"
          :turn="turn"
          :gameTurn="gameTurn"
          :room="room"
          :users="users"
          :wager="currentWager.wager"
          :playedCards="totalCardsInPlay"
        />
      </div>
      <remove-card
        :phase="phase"
        :numberOfCards="numberOfCards"
        :room="room"
        :removeCardColor="removeCardColor"
      />
    </div>
    <disconnect-modal
      v-bind:disconnect="disconnect"
      v-bind:disconnectedUser="disconnectedUser"
    />
    <game-over-modal
      v-if="gameTurn >= 0"
      v-bind:gameOver="gameOver"
      v-bind:winner="playerName(winner)"
    />
  </div>
</template>

<script>
import GameBoard from "../components/GameBoard.vue";
import DisconnectModal from "../components/DisconnectModal.vue";
import PlayCard from "../components/PlayCard.vue";
import Wager from "../components/Wager.vue";
import GameOverModal from "../components/GameOverModal.vue";
import RemoveCard from "../components/RemoveCard.vue";
import BlackCatIcon from "../assets/blackCards/BlackCatIcon.svg"
import BlueCatIcon from "../assets/blueCards/BlueCatIcon.svg"
import GreenCatIcon from "../assets/greenCards/GreenCatIcon.svg"
import PinkCatIcon from "../assets/pinkCards/PinkCatIcon.svg"
import RedCatIcon from "../assets/redCards/RedCatIcon.svg"
import YellowCatIcon from "../assets/yellowCards/YellowCatIcon.svg"

export default {
  props: {
    room: String,
    users: Object,
    turn: Number,
    registeredName: String,
    color: Number
  },
  components: {
    GameBoard,
    DisconnectModal,
    PlayCard,
    Wager,
    GameOverModal,
    RemoveCard,
    BlackCatIcon,
    BlueCatIcon,
    GreenCatIcon,
    PinkCatIcon,
    RedCatIcon,
    YellowCatIcon
  },
  computed: {
    userComponent() {
      let component = {}
      switch(this.color){
      case 0:
        component = BlackCatIcon
        break
      case 1:
        component = BlueCatIcon
        break
      case 2:
        component = GreenCatIcon
        break
      case 3:
        component = PinkCatIcon
        break
      case 4:
        component = RedCatIcon
        break
      case 5:
        component = YellowCatIcon
        break
      }
      return component
    },
    wagersLeft() {
      return this.wager[this.gameTurn];
    },
    startPlayer() {
      const startUser = Object.values(this.users).find(
        (user) => user.turn === 0
      );
      if (startUser === undefined) {
        return "";
      } else {
        return startUser.username;
      }
    },
    instruction() {
      const turnToString = this.playerName(this.gameTurn) + "'s turn to "
      let instructionString = ""
      switch(this.phase) {
        case "play":
          instructionString = turnToString + "play"
          break
        case "wager":
          instructionString = turnToString + "wager or pass"
          break
        case "wagerOrPlay":
          instructionString = turnToString + "wager or play"
          break
        case "guess":
          instructionString = turnToString + "guess"
          break
        case "self":
          instructionString = this.playerName(this.gameTurn) + " is choosing what card to lose"
          break
        case "skull":
          instructionString = "Waiting for " + this.playerName(this.skullPlayerIndex) + " to remove one of " + this.playerName(this.gameTurn) + "'s cards"
          break
      }
      return instructionString
    }
  },
  data() {
    return {
      gameStatus: "", // check if gameStatus and phase are the
      phase: "play",
      username: "",
      gameTurn: -1,
      cards: [],
      wager: [],
      correctGuesses: [],
      playedCards: [],
      wagerRange: [],
      removeCards: [],
      currentWager: {},
      numberOfCards: 0,
      disconnect: false,
      disconnectedUser: "",
      winner: 0,
      gameOver: false,
      totalCardsInPlay: 0,
      skullPlayerIndex: -1,
      outOfGame: false,
      removeCardColor: -1
    };
  },
  methods: {
    playerName(id) {
      return Object.values(this.users).find((user) => user.turn === id).username
    },
  },
  mounted() {
    const socket = this.$store.state.socket;

    socket.on("gameUpdate", (data, action) => {
      Object.assign(this, data);
      console.log(action);
      // this.currentWager = data.currentWager ? data.currentWager : 0 // check this
    });

    socket.on("not enough players", () => {
      this.gameStatus = "not enough players";
    });

    socket.on("disconnect game", (data) => {
      const { username } = data;
      if (!this.gameOver) {
        this.disconnectedUser = username;
        this.disconnect = true;
      }
    });

    socket.on("game over", (winner) => {
      this.winner = winner
      this.gameOver = true
    });

    socket.on("out of game", () => {
      this.outOfGame = true
    })
  }
};
</script>

<style>
#game {
  text-align: left;
}

.turn {
  border-top: 0.1rem solid #999;
  border-bottom: 0.1rem solid #999;
  width: 66%;
  margin: 2rem auto 1rem auto;
  text-align: center;
  padding: 0.5rem;
}

.card {
  width: 7rem;
  height: 10rem;
}

.player-box {
  bottom: 0;
  margin-top: 1.5rem;
  border: 0.8rem solid black;
  border-radius: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 38.5rem;
  padding-right: 1rem;
  position: relative;
  min-height: 15rem;
}

.player-actions {
  display: flex;
  justify-content: space-between;
}

.cat-icon {
  height: 4rem;
  width: 4rem;
  margin-right: 0.5rem;
}

.card-username {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.1rem;
  display: flex;
  width: 50%;
}
</style>
