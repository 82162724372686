<template>
  <div class="flex-container">
    <div class="start-game" v-on:click=startGame(room)>Start Game</div>
  </div>
</template>

<script>
export default {
  props: {
    room: String
  },
  methods: {
    startGame(room) {
      this.$store.state.socket.emit('start game', {room: room})
    }
  }
}
</script>

<style scoped>

.flex-container {
  display: flex;
  justify-content: center;
}

.start-game{
  border-radius: 3rem;
  background: #b3b3b3ff;
  padding: 10px;
  border: 2px solid #eee;
  outline: none;
  display: inline-block;
  color: black;
}
.start-game:hover {
  background-color: darkgray;
}
</style>
