<template>
  <url />
  <div class="flex-container" v-if="this.startGame === false">
    <username-input :room="room" :users="users" :turn="turn" :color="color" />
    <!-- combine with bottom with pencil icon -->
    <start-game v-if="turn === 0" :room="room" />
    <wait-for-start v-if="turn !== 0" />
  </div>
  <game
    :users="users"
    :room="room"
    :turn="turn"
    :registeredName="registeredName"
    :color="color"
  />
</template>

<script>
import UsernameInput from "../components/UsernameInput.vue";
import StartGame from "../components/StartGame.vue";
import WaitForStart from "../components/WaitForStart.vue";
import Url from "../components/Url.vue";
import Game from "./Game.vue"
import store from "../store.js";

export default {
  components: {
    UsernameInput,
    StartGame,
    WaitForStart,
    Url,
    Game
  },
  computed: {
    color() {
      if(Object.keys(this.users).length === 0 && this.users.constructor === Object ){
        return -1
      } else {
        return Object.values(this.users).find(user => user.turn === this.turn).color
      }
    }
  },
  data() {
    return {
      startGame: false,
      room: this.$route.path.substring(1),
      users: {},
      turn: -2,
      registeredName: '',
    }
  },
  mounted() {
    const socket = this.$store.state.socket;
    socket.emit("load", this.room);

    socket.on("user join", (data) => {
      this.users = data;
      this.turn = data[socket.id].turn;
      this.registeredName = data[socket.id].username;
    });

    socket.on("start game", () => {
      this.startGame = true;
    });

    socket.on("registered", (username) => {
      this.registeredName = username;
    });

    socket.on("change color", (users) => {
      this.users = users
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.socket.emit(
      "disconnect user",
      from.path.substring(1),
      this.registeredName
    );
    next();
  },
  beforeRouteEnter(to, from, next) {
    store.state.socket.emit("create or join", to.path.substring(1));

    store.state.socket.on("joined", () => {
      next();
    });

    // store.state.socket.on('full', (room) => {
    //   this.error = true
    //   console.log(room + "error")
    // })
  },
};
</script>
