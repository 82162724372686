<template>
  <button v-on:click="toggleColor">
    color
  </button>
  <div v-if="inputField" class="input-field">
    <button v-for="(color, index) in buttonStyling"
      :key="color"
      class="color-button"
      :class="colorClass(color, index)"
      v-on:click="chooseColor(index)"
    />
  </div>
</template>

<script>
export default {
  props: {
    room: String,
    users: Object
  },
  data() {
    return {
      inputField: false,
      buttonStyling: ["black", "blue", "green", "pink", "red", "yellow"]
    }
  },
  computed: {
    colorArray() {
      if(Object.keys(this.users).length === 0 && this.users.constructor === Object ){
        return -1
      } else {
        return Object.values(this.users).map(user => user.color)
      }
    },
  },
  methods: {
    toggleColor(){
      this.inputField = !this.inputField
    },
    chooseColor(index){
      this.$store.state.socket.emit('color', {room: this.room, color: index})
      this.toggleColor()
    },
    colorClass(color, index) {
      console.log(this.users)
      console.log(this.colorArray)
      return [
        color,
        this.colorArray.includes(index) ? 'deactivate' : ''
      ]
    }
  }
}
</script>

<style scoped>
.input-field {
  position: fixed;
  background: white;
}

.color-button {
  width: 2rem;
  height: 2rem;
}

.black {
  background: black;
}

.blue {
  background: blue;
}

.green {
  background: green;
}

.pink {
  background: pink;
}

.red {
  background: red;
}

.yellow {
  background: yellow;
}

.deactivate {
  opacity: 50%;
}

</style>
