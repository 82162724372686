<template>
  <div class="game-board">
    <div class="player" v-for="(player, index) in playerData" v-bind:key="index">
      <div class="player-name-container">
        <paw-print class="guess-mark" v-if="player.correctGuess"/>
        <div class="player-name" :class="{'show-turn': player.id === gameTurn}">
          {{player.username}}
        </div>
      </div>
      <span class="hover" :class="{'hover-hack': selectionDisable(index)}" v-on:click="guessFlip(player.id)">
        <component :is="placemat(player.color)" class="placemat"/>
        <component :is="back(player.color)" v-for="number in player.card" class="board-card" :style="cardStackStyle(number-1)" :key="number"/>
        <span v-for="number in player.flippedCards" :key="number">
          <component :is="cat(player.color)" v-if="(phase.includes('skull') || phase.includes('self')) && index === skullPlayerIndex" class="flipped-card" :style="cardStackStyle(number-1)"/>
          <component :is="flower(player.color)" v-else class="flipped-card" :style="cardStackStyle(number-1)"/>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import BlackFlower from '../assets/blackCards/BlackFlower.svg'
import BlackCat from '../assets/blackCards/BlackCat.svg'
import BlackPlacemat from '../assets/blackCards/BlackPlacemat.svg'
import BlackBack from '../assets/blackCards/BlackBack.svg'
import BlueFlower from '../assets/blueCards/BlueFlower.svg'
import BlueCat from '../assets/blueCards/BlueCat.svg'
import BluePlacemat from '../assets/blueCards/BluePlacemat.svg'
import BlueBack from '../assets/blueCards/BlueBack.svg'
import GreenFlower from '../assets/greenCards/GreenFlower.svg'
import GreenCat from '../assets/greenCards/GreenCat.svg'
import GreenPlacemat from '../assets/greenCards/GreenPlacemat.svg'
import GreenBack from '../assets/greenCards/GreenBack.svg'
import PinkFlower from '../assets/pinkCards/PinkFlower.svg'
import PinkCat from '../assets/pinkCards/PinkCat.svg'
import PinkPlacemat from '../assets/pinkCards/PinkPlacemat.svg'
import PinkBack from '../assets/pinkCards/PinkBack.svg'
import RedFlower from '../assets/redCards/RedFlower.svg'
import RedCat from '../assets/redCards/RedCat.svg'
import RedPlacemat from '../assets/redCards/RedPlacemat.svg'
import RedBack from '../assets/redCards/RedBack.svg'
import YellowFlower from '../assets/yellowCards/YellowFlower.svg'
import YellowCat from '../assets/yellowCards/YellowCat.svg'
import YellowPlacemat from '../assets/yellowCards/YellowPlacemat.svg'
import YellowBack from '../assets/yellowCards/YellowBack.svg'
import PawPrint from '../assets/PawPrint.svg'

export default {
  components: {
    BlackFlower,
    BlackCat,
    BlackPlacemat,
    BlackBack,
    BlueFlower,
    BlueCat,
    BluePlacemat,
    BlueBack,
    GreenFlower,
    GreenCat,
    GreenPlacemat,
    GreenBack,
    PinkFlower,
    PinkCat,
    PinkPlacemat,
    PinkBack,
    RedFlower,
    RedCat,
    RedPlacemat,
    RedBack,
    YellowFlower,
    YellowCat,
    YellowPlacemat,
    YellowBack,
    PawPrint
  },
  props: {
    users: Object,
    currentUser: Number,
    playedCards: Array,
    correctGuesses: Array,
    phase: String,
    gameTurn: Number,
    skullPlayerIndex: Number,
    color: Number
  },
  computed: {
    playerData() {
      return this.playedCards.map((player, index) => {
        const user = Object.values(this.users).find(user => user.turn === player.id)
        return {
          card: player.length,
          username: user.username,
          color: user.color,
          id: player.id,
          correctGuess: this.correctGuesses[index],
          flippedCards: player.flippedCards
        }
      })
    },
  },
  data() {
    return {
      room: this.$route.path.substring(1),
    }
  },
  methods: {
    selectionDisable(index) {
      return this.gameTurn === this.currentUser && this.phase.includes('guess') && this.gameTurn !== index && this.playedCards[index].length > 0
    },
    guessFlip(index){
      if(this.selectionDisable(index)){
        this.$store.state.socket.emit('guess', {room: this.room, choice: index})
      }
    },
    cardStackStyle(card) {
      const staggerWeight = card * .5
      return {
        transform: 'translate(' + staggerWeight + 'rem,' + staggerWeight + 'rem)'
      }
    },
    flower(playerColor) {
      let component = {}
      switch(playerColor){
      case 0:
        component = BlackFlower
        break
      case 1:
        component = BlueFlower
        break
      case 2:
        component = GreenFlower
        break
      case 3:
        component = PinkFlower
        break
      case 4:
        component = RedFlower
        break
      case 5:
        component = YellowFlower
        break
      }
      return component
    },
    cat(playerColor) {
      let component = {}
      switch(playerColor){
      case 0:
        component = BlackCat
        break
      case 1:
        component = BlueCat
        break
      case 2:
        component = GreenCat
        break
      case 3:
        component = PinkCat
        break
      case 4:
        component = RedCat
        break
      case 5:
        component = YellowCat
        break
      }
      return component
    },
    placemat(playerColor) {
      let component = {}
      switch(playerColor){
      case 0:
        component = BlackPlacemat
        break
      case 1:
        component = BluePlacemat
        break
      case 2:
        component = GreenPlacemat
        break
      case 3:
        component = PinkPlacemat
        break
      case 4:
        component = RedPlacemat
        break
      case 5:
        component = YellowPlacemat
        break
      }
      return component
    },
    back(playerColor) {
      let component = {}
      switch(playerColor){
      case 0:
        component = BlackBack
        break
      case 1:
        component = BlueBack
        break
      case 2:
        component = GreenBack
        break
      case 3:
        component = PinkBack
        break
      case 4:
        component = RedBack
        break
      case 5:
        component = YellowBack
        break
      }
      return component
    },
  }
}
</script>

<style>
.game-board{
  display: -webkit-flex;
  position: relative;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  margin-top: 2rem;
}

.player{
  width: 18rem;
  height: 15rem;
  position: relative;
}

.player-name-container{
  font-weight: bold;
  padding-left: 1.1rem;
  padding-top: .1rem;
  padding-bottom: .1rem;
}

.player-name{
  color: black;
  display:inline-block;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
}

.show-turn {
  border-radius: 1rem;
  background: #5599FF;
  opacity: 1;
  visibility: visible;
  transition: background 150ms ease-in 100ms;
}

.guess-mark{
  width: 1rem;
  height: 1rem;
}

.board-card{
  width: 7rem;
  height: 10rem;
  position: absolute;
  top: 1.2rem;
  left: 1rem;
}

.flipped-card{
  width: 7rem;
  height: 10rem;
  position: absolute;
  top: 1.2rem;
  left: 8.2rem;
}

.placemat{
  margin-top: .1rem;
  width: 20rem;
  height: 14rem;
  position: absolute;
  left: -1.1rem;
}

.hover {
  position: absolute;
}

.hover-hack:hover{
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 50%;
}


</style>
